import { useCallback, useMemo } from 'react'
import { useDeviceHttpProxy } from '@/hooks/DeviceHttpProxy/useDeviceHttpProxy'
import { GET_CONSUMABLE_SUPPLIES_SERVICE } from '@/constants/DeviceHttpProxy'
import {
  getAndParseListElementsIntoArray,
  getFirstElementByTag,
  getFirstTextContentByTag
} from '@/utils/DeviceHttpProxy'

export const CMY_COLOR_CODE = 'CMY'
export const CARTRIDGE_INK_TYPE = 'inkCartridge'

const CONSUMABLE_INFO_KEY = 'ccdyn:ConsumableInfo'
const CONSUMABLE_TYPE_KEY = 'dd:ConsumableTypeEnum'
const CONSUMABLE_LABEL_CODE_KEY = 'dd:ConsumableLabelCode'

function isCMYInkSupportedLedm(xml) {
  return getAndParseListElementsIntoArray(xml, CONSUMABLE_INFO_KEY).some(
    (e) => {
      const consumableType = getFirstTextContentByTag(e, CONSUMABLE_TYPE_KEY)
      const colorCode = getFirstTextContentByTag(e, CONSUMABLE_LABEL_CODE_KEY)
      return (
        consumableType === CARTRIDGE_INK_TYPE &&
        colorCode === CMY_COLOR_CODE
      )
    }
  )
}

function isCMYInkSupportedCdm(data) {
  return data.suppliesList.some(({ supplyType, supplyColorCode }) => {
    return (
      supplyType === CARTRIDGE_INK_TYPE &&
      supplyColorCode === CMY_COLOR_CODE
    )
  })
}

export function usePrinterConsumableSupplies() {
  const { sendRequest, isCdm } = useDeviceHttpProxy()

  const isCMYInkSupported = useCallback(async () => {
    const { body } = await sendRequest(GET_CONSUMABLE_SUPPLIES_SERVICE)
    return isCdm
      ? isCMYInkSupportedCdm(body.data)
      : isCMYInkSupportedLedm(body.data)
  }, [sendRequest, isCdm])

  return useMemo(
    () => ({
      isCMYInkSupported
    }),
    [isCMYInkSupported]
  )
}
