import React, { Suspense, useContext, useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Select from '@veneer/core/dist/scripts/select'
import TextArea from '@veneer/core/dist/scripts/text_area'
import { IconMinusCircle, IconWarningAlt } from '@/styles/LazyVeneer'
import { PrinterContext, UserContext } from '@/store'

import classes from './CreateDomain.module.scss'
import useAnalytics from '@/hooks/useAnalytics'
import { ANALYTICS } from '@/store/Constants'
import SupportedCountriesModal from '@/components/UI/Modals/SupportedCountriesModal'

const { SCREENS, BUTTONS, MODES, LINKS } = ANALYTICS

export function CreateDomain({
  pageData,
  enteredOrganization,
  organizationEntryHandler,
  retry,
  isOrganizationNameValid,
  hpPlusCountrySelected,
  selectedCountry,
  countrySelectorHandler,
  setEnteredOrganizationDescription,
  doDomainsExist
}) {
  const { supportedCountries } = useContext(UserContext)
  const { isHpPlus } = useContext(PrinterContext)
  const [
    showSupportedCountriesModal,
    setShowSupportedCountriesModal
  ] = useState(false)
  const {
    onbpReleaseOrgcountryddassignprinterowner: isCountrySelectorEnabled
  } = useFlags()
  const analyticsUnsupportedCountryError = useAnalytics(
    ANALYTICS.SCREENS.COUNTRY_UNSUPPORTED_ERROR,
    false
  )
  const analyticsUnsupportedCountryErrorList = useAnalytics(
    SCREENS.COUNTRY_UNSUPPORTED_ERROR_SUPPORTED_LIST,
    false
  )
  const analyticsCompanyName = useAnalytics(SCREENS.COMPANY_NAME)
  const analyticsDomainErrorInline = useAnalytics(SCREENS.DOMAIN_ERROR_INLINE)
  const invalidOrgName =
    enteredOrganization.trim().length > 0 && !isOrganizationNameValid

  const supportedCountriesClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      analyticsUnsupportedCountryError.fireHyperLinkClick(
        LINKS.SUPPORTED_COUNTRIES,
        { mode: MODES.DOMAIN[doDomainsExist] }
      )
      setShowSupportedCountriesModal(true)
      analyticsUnsupportedCountryErrorList.fireScreenDisplayed({
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }
  }

  const termsOfUseClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      analyticsCompanyName.fireHyperLinkClick(LINKS.TOU, {
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }
  }

  useEffect(() => {
    if (invalidOrgName) {
      analyticsDomainErrorInline.fireScreenDisplayed({
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }
  }, [analyticsDomainErrorInline, doDomainsExist, invalidOrgName])

  return (
    <div className={classes.createDomainSection}>
      {showSupportedCountriesModal && (
        <SupportedCountriesModal
          onClose={() => {
            analyticsUnsupportedCountryErrorList.fireButtonClick(BUTTONS.CLOSE)
            setShowSupportedCountriesModal(false)
            analyticsUnsupportedCountryError.fireScreenDisplayed({
              mode: MODES.DOMAIN[doDomainsExist],
              force: true
            })
          }}
          analyticsScreen={SCREENS.COUNTRY_UNSUPPORTED_ERROR_SUPPORTED_LIST}
        />
      )}
      <TextBox
        data-testid="organization-entry"
        id="organization"
        value={enteredOrganization}
        onChange={organizationEntryHandler}
        className={classes.textBox}
        label={pageData.organization_name}
        disabled={retry}
        maxLength={1024}
      />
      {invalidOrgName && (
        <div
          className={classes.inlineError}
          data-testid="invalid-org-name-error"
        >
          <Suspense fallback={<></>}>
            <IconMinusCircle filled={true} color="red7" size={20} />
          </Suspense>
          <span>{pageData.invalid_name}</span>
        </div>
      )}
      {isCountrySelectorEnabled && supportedCountries?.length > 0 && (
        <div data-testid="select_field">
          <Select
            data-testid="veneer-select"
            error={isHpPlus && !hpPlusCountrySelected}
            options={[
              {
                value: '',
                label: pageData.organization_country
              },
              ...supportedCountries
            ]}
            label={pageData.organization_country}
            visibleOptions={5}
            value={[selectedCountry]}
            onChange={(selected) => {
              countrySelectorHandler(selected)
            }}
            className={classes.select}
            clearIcon={false}
            disabled={retry}
          />
          {isHpPlus && !hpPlusCountrySelected && (
            <div className={classes.inlineError} data-testid="country-error">
              <span>
                <Suspense fallback={<></>}>
                  <IconWarningAlt filled={true} size={20} />
                </Suspense>
              </span>
              <p
                onClick={supportedCountriesClickHandler}
                dangerouslySetInnerHTML={{
                  __html: pageData.country_error
                }}
              />
            </div>
          )}
        </div>
      )}
      <TextArea
        id="organization-description"
        className={classes.textArea}
        label={pageData.organization_description}
        disabled={retry}
        onChange={(e) => {
          setEnteredOrganizationDescription(e)
        }}
        maxLength={1024}
      />
      <p
        data-testid="assign-org-tou"
        onClick={termsOfUseClickHandler}
        dangerouslySetInnerHTML={{
          __html: pageData.agree_terms
        }}
      />
    </div>
  )
}
